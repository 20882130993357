<template>
  <div id="app-container" class="row align-items-center justify-content-center">
    <div class="terminal-bg">
      <section id="terminal">
        <router-view />
      </section>
    </div>
    <div class="text">
      <h2>
        <a href="https://donateo.pl/" target="_blank"
          ><img
            class="donateo-logo"
            :src="require('@/assets/img/logo-donateo.svg')" /></a
        ><br /><span>#WarszawskieŚniadanieWielkanocnedlaSamotnych!</span>
      </h2>
      <h1>
        Przekaż datek na konto Fundacji Wolne Miejsce, która zaprasza do wielkanocnego stołu każdą samotną osobę      </h1>

      <p>Dzięki Twojej wpłacie:</p>
      <ul>
        <li>zorganizujemy w dniu 31 marca 2024 r. Warszawskie Śniadanie Wielkanocne dla Samotnych </li>
        <li>przygotujemy tysiące wielkanocnych śniadań dla każdej potrzebującej osoby</li>
        <li>
          zapewnimy wsparcie, dobre słowo, uśmiech i obecność
        </li>
      </ul>
      <p>Dziękujemy Ci za #MocDobrychGestów!</p>
      <p>
        Każda kwota trafi bezpośrednio na konto<br /><strong
          >Fundacji Wolne Miejsce.</strong
        ><br />
      </p>
      <img :src="require('@/assets/img/logo.png')" alt="" />
    </div>
    <div class="mobile-text" v-if="showText">
      <div>
        <img :src="require('@/assets/img/logo.png')" alt="" />
      </div>
      <h2>
        <a href="https://donateo.pl/" target="_blank"
          ><img
            class="donateo-logo"
            :src="require('@/assets/img/logo-donateo.svg')" /></a
        ><br /><span>#WarszawskieŚniadanieWielkanocnedlaSamotnych!</span>
      </h2>
      <h1>Przekaż datek na konto Fundacji Wolne Miejsce, która zaprasza do wielkanocnego stołu każdą samotną osobę
      </h1>
      <p>Dzięki Twojej wpłacie:</p>
      <ul>
        <li>zorganizujemy w dniu 31 marca 2024 r. Warszawskie Śniadanie Wielkanocne dla Samotnych </li>
        <li>przygotujemy tysiące wielkanocnych śniadań dla każdej potrzebującej osoby</li>
        <li>
          zapewnimy wsparcie, dobre słowo, uśmiech i obecność
        </li>
      </ul>
      <p>Dziękujemy Ci za #MocDobrychGestów!</p>
      <p>
        Każda kwota trafi bezpośrednio na konto<br /><strong
          >Fundacji Wolne Miejsce.</strong
        ><br />
      </p>
    </div>
    <div class="ibtn" v-on:click="showText = !showText">
      <img :src="require('@/assets/img/i_btn.png')" alt="" v-if="!showText" />
      <img :src="require('@/assets/img/x_btn.png')" alt="" v-if="showText" />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showText: false,
    };
  },
};
</script>

<style lang="scss">
@import "./assets/styles/App.css";
</style>
